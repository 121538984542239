import React from "react";
import { lazy } from "react";
import { authClientUrls } from "@lb/utils";
import AppLayout from "./layouts/AppLayout";
import { Navigate, createBrowserRouter } from "react-router-dom";

const AuthLayout = lazy(() => import("./layouts/AuthLayout"));
const Signature = lazy(() => import("./pages/signature"));
const Custody = lazy(() => import("./pages/custody"));
const WildcardAccess = lazy(() => import("./pages/wildcard-access"));
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const AcceptInvitation = lazy(() => import("./pages/auth/AcceptInvitation"));
const VendorOnboarding = lazy(() => import("./pages/auth/VendorOnboarding"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { index: true, element: <Navigate to={authClientUrls.base} replace /> },
      {
        path: authClientUrls.slug,
        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={authClientUrls.login.base} replace />,
          },
          { path: authClientUrls.login.slug, element: <Login /> },
          { path: authClientUrls.register.slug, element: <Register /> },
          {
            path: authClientUrls.forgotPassword.slug,
            element: <ForgotPassword />,
          },
          {
            path: authClientUrls.resetPassword.slug,
            element: <ResetPassword />,
          },
          {
            path: authClientUrls.acceptInvite.slug,
            element: <AcceptInvitation />,
          },
          { path: authClientUrls.vendor.slug, element: <VendorOnboarding /> },
          { path: "*", element: <Navigate to={authClientUrls.login.base} replace /> },
        ],
      },
      {
        path: authClientUrls.custody.slug,
        element: <Custody />,
      },
      {
        path: authClientUrls.signature.slug,
        element: <Signature />,
      },
      {
        path: authClientUrls.wildcard.slug,
        element: <WildcardAccess />,
      },
      { path: "*", element: <Navigate to={authClientUrls.login.base} replace /> },
    ],
  },
]);

export default router;
