import { LbButton, useOrganization, LbLogoLoading } from "@lb/frontend";
import { Container, Link, Stack, Toolbar, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { VscQuestion } from "react-icons/vsc";
import { Outlet } from "react-router-dom";

export default function AppLayout() {
  const { organization } = useOrganization();
  return (
    <div>
      <Stack justifyContent={"space-between"} minHeight="100vh">
        <Toolbar variant="dense">
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              height={30}
              alt="organization-logo"
              style={{ objectFit: "contain" }}
              src={
                organization?.trademark?.url ||
                `${process.env.REACT_APP_ASSETS_URL}/logos/lb.png`
              }
            />

            <LbButton
              variant={"text"}
              tabIndex={-1}
              endIcon={<VscQuestion />}
              component={Link}
              href="https://legalbuddy.in/contact-us/"
              target="_blank"
            >
              Need Help
            </LbButton>
          </Container>
        </Toolbar>
        <Suspense fallback={<LbLogoLoading />}>
          <Outlet />
        </Suspense>
        <Typography
          p={1.5}
          variant="body2"
          color="text.secondary"
          align="center"
          borderTop="1px solid"
          borderColor="grey.200"
          bgcolor={"white"}
        >
          {"Copyright © "}
          <Link color="inherit" href="https://legalbuddy.in" tabIndex={-1}>
            legalbuddy.in
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Stack>
    </div>
  );
}
